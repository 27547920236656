"use strict";

//Fix AFUI bugs
module.exports = function() {
	//Future: A newer AFUI3 Beta exists that may render some of this unnecessary: https://github.com/01org/appframework/tree/master
	//Todo: Some of these methods have stopped working.
	
	/* globals $, jQuery */
	
	// Fire loadpanel event after launch.
	// Future: If this is moved back into afui, it should be placed into the launch method after firstPanel.addClass("active");
	(function($){
		var afuiReadyCb = function(){
			$($.afui.activeDiv).trigger("panelload");
		};
		
		$.afui.ready(afuiReadyCb);
	})(jQuery);
	    
	//Fire events in goBack
	$.afui.goBack = function(e) {
        //find the view
        var isSameView = true;
        var currentView=$(this.activeDiv).closest(".view");
        var targetView=currentView;
        if(e&&e.target) {
            targetView=$(e.target).closest(".view");
            isSameView = targetView.prop("id") === (currentView.prop("id"));
        }

        if(targetView.length===0) return;

        //history entry
        if(!this.views[targetView.prop("id")]) return;
        var hist=this.views[targetView.prop("id")];

        if(hist.length===0) return;
        var item=hist.pop();
        //If not in same view, just push back to keep last states
        if (!isSameView) {
            hist.push(item);
        }
        if(item.length===0) return;
        if(hist.length>0){
            var toTarget = hist[hist.length-1].target;
            if(!toTarget) return;
            if(isSameView && (item.target===toTarget)) return;
            
            $(this.activeDiv).trigger("panelbeforeunload");

            if (!isSameView) {
                this.clearHistory(); //Clear current view history
                $(item.target).trigger("panelbeforeload");
                this.runViewTransition(this.transitionType, targetView, currentView, item.target, true);
            }
            else {
                $(toTarget).trigger("panelbeforeload");
                this.runTransition(item.transition, item.target, toTarget, true);
            }
            this.loadContentData(toTarget, targetView, true);
            this.updateHash(toTarget.id);
        }
        else {
            //try to dismiss the view
            try{
                $(item.target).trigger("panelbeforeload");
                this.dismissView(item.target,item.transition);
            }
            catch(ex){
            }

        }
    };
	
	//clearHistory breaks the history stack and permenately removes the back button.
	$.afui.clearHistory = function() {
		// Future: Determine if this is already stored in $.afui
		var activeView = $(".view.active").eq(0);
		var hdr = activeView.children("header");
		var viewHistory = this.views[activeView.prop("id")];
		viewHistory = viewHistory.splice(0, viewHistory.length - 1);
		hdr.find(".backButton").remove();
	};
	
	//Scroll to top of div before it is displayed.
    $.afui.runTransition = function(transition,previous,target, back,noTrans) {

        if(!transition)
            transition="slide";

        if(transition.indexOf(":back")!==-1){
            transition=transition.replace(":back","");
            back=true;
        }

        var that=this;
        var show=back?previous:target;
        var hide=back?target:previous;
        var doPush=false;
        if(transition.indexOf("-reveal")!==-1){
            transition=transition.replace("-reveal","");
            doPush=true;
        }

        $(show).css("zIndex","10");
        $(hide).css("zIndex","1");
        
        if(back) {
        	$(hide).show();
        	$(hide).scrollTop(0);
        	$(hide).hide();
        } else {
            $(show).show();
            $(show).scrollTop(0);
            $(show).hide();
        }
        
        $(noTrans).css("zIndex","1").addClass("active");

        var from=$(hide).animation().remove(transition+"-in");
        //Handle animating the reverse when there is one.
        //In the push case, it's static and needs to stay available until
        //the other view finishes animation.
        if(!doPush&&from){
            if(back) {
                from.reverse();
            }
            from.end(function(){
                if(!back){
                    this.classList.remove("active");
                    //If 'this' is view, then find active panel and remove active from it
                    var tmpActive = $(this).find(".active").get(0);
                    if (undefined !== tmpActive) {
                        $(tmpActive).trigger("panelunload", [back]);
                        tmpActive.classList.remove("active");
                    }
                    //Below trigger will be called when 'to animation' done
                    //$(this).trigger("panelunload", [back]);
                }
                else{
                    this.classList.add("active");
                    $(this).trigger("panelload", [back]);
                }
                that.doingTransition=false;
            }).run(transition+"-out");
        }

        var to=$(show).animation().remove(transition+"-out");
        if(back) {
            to.reverse();
        }
        to.end(function(){

            that.doingTransition=false;
            var tmpActive;
            if(!back){
                this.classList.add("active");
                $(this).trigger("panelload", [back]);
                $(noTrans).trigger("panelload", [back]);

                //Previous panel needs to be hidden after animation
                //Fixes #850, #860, #873
                tmpActive = $(hide).find(".active").get(0);
                if (undefined !== tmpActive) {
                    $(tmpActive).trigger("panelunload", [back]);
                    tmpActive.classList.remove("active");

                }
                //fix #903
                //$(hide).trigger("panelunload", [back]);
            }
            else{
                if(noTrans){
                    $(noTrans).css("zIndex","10");
                }
                this.classList.remove("active");
                //If 'hide' is view, then find active panel and remove active from it
                tmpActive = $(this).find(".active").get(0);
                if (undefined !== tmpActive) {
                    $(tmpActive).trigger("panelunload", [back]);
                    tmpActive.classList.remove("active");
                }
                $(hide).trigger("panelload", [back]);
                $(hide).addClass("active");
                $(this).trigger("panelunload", [back]);
            }

        }).run(transition+"-in");
    };
    
	//Overwrite $.afui.showMask so that it does not set a default timeout.
	$.afui.showMask = function(text, value) {
		var timeout = true;
		if (!text) text = this.loadingText || "";
		if (!value || typeof value !== "number") timeout = false;
		$.query("#afui_mask>h1").html(text);
		$.query("#afui_mask").show();
		this.showingMask = true;

		var self = this;
		//If timeout was specified, set another timeout to auto-hide the mask
		if(timeout) {
			window.setTimeout(function() {
			if(self.showingMask) {
				self.hideMask();
				}
			}, value);
		}
	};
	
	//Add $.afui.showingPopup
	//Add $.afui.uiBlocked
	//Added autoCloseCancel
	//Correct popup position on orientation change.
	//Correct popup overflow.
	//Replace alert with console.log
	//Maintain uiBlocks while masks or popups are showing
	//Future: Consider: http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
	//Note: window.orientation is depriciated, however the replacement, Screen.orientation, is not finalized.
	(function($) {

		$.afui.showingPopup = false;
		
		$.fn.popup = function(opts) {
			return new Popup(this[0], opts);
		};
		
		var queue = [];
		var oldDocumentBodyClientWidth;
		var oldDocumentBodyClientHeight;
		var documentBodyClientWidth;
		var documentBodyClientHeight;
		var delayedCheckOrientationCount = 0;
		var delayIntervalId;
		var fallbackIntervalId;
		var orientationchangeSupport;
		var orientationchangeCb;
		var $window = $(window);
		
		var Popup = function(containerEl, opts) {

			if (typeof containerEl === "string" || containerEl instanceof String) {
				this.container = document.getElementById(containerEl);
			} else {
				this.container = containerEl;
			}
			if (!this.container) {
				window.console.log("Error finding container for popup " + containerEl);
				return;
			}

			try {
				if (typeof(opts) === "string" || typeof(opts) === "number")
					opts = {
						message: opts,
						cancelOnly: "true",
						cancelText: "OK"
					};
				this.id = opts.id = opts.id || $.uuid(); //opts is passed by reference
				this.addCssClass = opts.addCssClass ? opts.addCssClass : "";
				this.suppressTitle = opts.suppressTitle || this.suppressTitle;
				this.title = opts.suppressTitle ? "" : (opts.title || "Alert");
				this.message = opts.message || "";
				this.cancelText = opts.cancelText || "Cancel";
				this.cancelCallback = opts.cancelCallback || function() {};
				this.cancelClass = opts.cancelClass || "button";
				this.doneText = opts.doneText || "Done";
				this.doneCallback = opts.doneCallback || function() {
					// no action by default
				};
				this.doneClass = opts.doneClass || "button";
				this.cancelOnly = opts.cancelOnly || false;
				this.onShow = opts.onShow || function() {};
				this.autoCloseDone = opts.autoCloseDone !== undefined ? opts.autoCloseDone : true;
				this.autoCloseCancel = opts.autoCloseDone !== undefined ? opts.autoCloseDone : true;

				queue.push(this);
				if (queue.length === 1)
					this.show();
			} catch (e) {
				console.log("error adding popup " + e);
			}

		};

		Popup.prototype = {
			id: null,
			addCssClass: null,
			title: null,
			message: null,
			cancelText: null,
			cancelCallback: null,
			cancelClass: null,
			doneText: null,
			doneCallback: null,
			doneClass: null,
			cancelOnly: false,
			onShow: null,
			autoCloseDone: true,
			autoCloseCancel: true,
			suppressTitle: false,
			show: function() {
				var self = this;
				var markup = "<div id='" + this.id + "' class='afPopup hidden " + this.addCssClass + "'>" +
					"<header>" + this.title + "</header>" +
					"<div>" + this.message + "</div>" +
					"<footer>" +
					"<a href='javascript:;' class='" + this.cancelClass + "' id='cancel'>" + this.cancelText + "</a>" +
					"<a href='javascript:;' class='" + this.doneClass + "' id='action'>" + this.doneText + "</a>" +
					"<div style='clear:both'></div>" +
					"</footer>" +
					"</div>";

				var $el = $(markup);
				$(this.container).append($el);
				$el.bind("close", function() {
					self.hide();
				});

				if (this.cancelOnly) {
					$el.find("A#action").hide();
					$el.find("A#cancel").addClass("center");
				}
				$el.find("A").each(function() {
					var button = $(this);
					button.bind("click", function(e) {
						self.hide(button.attr("id"));
						if (button.attr("id") === "cancel") {
							self.cancelCallback.call(self.cancelCallback, self);
							if (self.autoCloseCancel)
								self.hide();
						} else {
							self.doneCallback.call(self.doneCallback, self);
							if (self.autoCloseDone)
								self.hide();
						}
						e.preventDefault();
					});
				});
				
				documentBodyClientWidth = document.body.clientWidth;
				documentBodyClientHeight = document.body.clientHeight;
				oldDocumentBodyClientWidth = documentBodyClientWidth;
				oldDocumentBodyClientHeight = documentBodyClientHeight;
				
				self.positionPopup();
				$.afui.showingPopup = true;
				$.blockUI(0.5);
				
				orientationchangeSupport = "onorientationchange" in window;
				
				var checkOrientation = function() {
					documentBodyClientWidth = document.body.clientWidth;
					documentBodyClientHeight = document.body.clientHeight;
					
					if (documentBodyClientHeight === oldDocumentBodyClientHeight && documentBodyClientWidth === oldDocumentBodyClientWidth) {
						return false;
					}
					
					if(typeof delayIntervalId !== "undefined") {
						clearInterval(delayIntervalId);
						delayIntervalId = undefined;
						delayedCheckOrientationCount = 0;
					}
					
					oldDocumentBodyClientHeight = documentBodyClientHeight;
					oldDocumentBodyClientWidth = documentBodyClientWidth;
					
					self.positionPopup();
				};
				
				var delayedCheckOrientation = function() {
					delayedCheckOrientationCount++;
					
					if(delayedCheckOrientationCount >= 200 && typeof delayIntervalId !== "undefined") {
						clearInterval(delayIntervalId);
						delayIntervalId = undefined;
						delayedCheckOrientationCount = 0;
					}
					
					checkOrientation();
					
				};

				orientationchangeCb = function() {
					if(!checkOrientation() && typeof delayIntervalId !== "undefined") {
						//If this point is reached, we are on Android or an OS that needs the same behavior.
						delayIntervalId = setInterval(delayedCheckOrientation, 10);
					}
				};
				
				$window.bind("resize", orientationchangeCb);

				if(orientationchangeSupport) {
					$window.bind("orientationchange", orientationchangeCb);
				}
				
				if($.os.android) {
					fallbackIntervalId = setInterval(checkOrientation, 2000);
				}

				//force header/footer showing to fix CSS style bugs
				$el.find("header").show();
				$el.find("footer").show();
				setTimeout(function() {
					$el.removeClass("hidden").addClass("show");
					self.onShow(self);
				}, 50);
			},

			hide: function() {
				var self = this;
				$.query("#" + self.id).addClass("hidden");
				$("BODY").css('overflow', 'hidden');
				
				if(typeof delayIntervalId !== "undefined") {
					clearInterval(delayIntervalId);
					delayIntervalId = undefined;
					delayedCheckOrientationCount = 0;
				}
				
				if(typeof fallbackIntervalId !== "undefined") {
					clearInterval(fallbackIntervalId);
					fallbackIntervalId = undefined;
				}
				
				if (!$.os.ie && !$.os.android) {
					setTimeout(function() {
						self.remove();
					}, 250);
				} else
					self.remove();
			},

			remove: function() {
				var self = this;
				var $el = $.query("#" + self.id);
				$el.unbind("close");
				$el.find("BUTTON#action").unbind("click");
				$el.find("BUTTON#cancel").unbind("click");

				$window.unbind("resize", orientationchangeCb);

				if(orientationchangeSupport) {
					$window.unbind("orientationchange", orientationchangeCb);
				}
				$el.remove();
				$.afui.showingPopup = false;
				$.unblockUI();
				queue.splice(0, 1);
				if (queue.length > 0)
					queue[0].show();
			},

			positionPopup: function() {
				var popup = $.query("#" + this.id);
				var popupClientHeight = popup[0].clientHeight;
				var popupCssTop = Math.round(((documentBodyClientHeight / 2.5) + window.pageYOffset) - (popupClientHeight / 2));
				
				if(popupCssTop < 0) {
					popupCssTop = 0;
				}
				
				var popupCssTopPx = popupCssTop + "px";
				
				popup.css("top", popupCssTop);
				popup.css("left", (document.body.clientWidth / 2) - (popup[0].clientWidth / 2) + "px");
				
				if (documentBodyClientHeight < popupClientHeight) {
					$("BODY").css('overflow', 'auto');
				}
			}
		};
		$.afui.uiBlocked = false;
		$.blockUI = function(opacity) {
			if ($.afui.uiBlocked)
				return;
			opacity = opacity ? " style='opacity:" + opacity + ";'" : "";
			$.query("BODY").prepend($("<div id='mask'" + opacity + "></div>"));
			$.query("BODY DIV#mask").bind("touchstart", function(e) {
				e.preventDefault();
			});
			$.query("BODY DIV#mask").bind("touchmove", function(e) {
				e.preventDefault();
			});
			$.afui.uiBlocked = true;
		};

		$.unblockUI = function() {
			if ($.afui.showingPopup || $.afui.showingMask) {
				return;
			}
			$.afui.uiBlocked = false;
			$.query("BODY DIV#mask").unbind("touchstart");
			$.query("BODY DIV#mask").unbind("touchmove");
			$("BODY DIV#mask").remove();
		};


		$.afui.registerDataDirective("[data-alert]", function(item) {
			var $item = $(item);
			var message = $item.attr("data-message");
			if (message.length === 0) return;
			$(document.body).popup(message);
		});

		$.afui.popup = function(opts) {
			return $(document.body).popup(opts);
		};

	})(jQuery);
	
	//Reverse events
	$.afui.loadDiv = function(target, newView, back, transition, anchor) {
        // load a div
        var newDiv = target;

        var hashIndex = newDiv.indexOf("#");
        var slashIndex = newDiv.indexOf("/");
        if ((slashIndex !== -1)&&(hashIndex !== -1)) {
            //Ignore everything after the slash in the hash part of a URL
            //For example: app.com/#panelid/option1/option2  will become -> app.com/#panelid
            //For example: app.com/path/path2/path3  will still be -> app.com/path/path2/path3
            if (slashIndex > hashIndex) {
                newDiv = newDiv.substr(0, slashIndex);
            }
        }
        newDiv = newDiv.replace("#", "");

        newDiv = $.query("#" + newDiv).get(0);
        if (!newDiv) {
            $(document).trigger("missingpanel", null, {missingTarget: target});
            this.doingTransition=false;
            return;
        }

        if (newDiv === this.activeDiv && !back) {
            //toggle the menu if applicable
            this.doingTransition=false;
            return;
        }

        this.transitionType = transition;

        var view=this.findViewTarget(newDiv);
        var previous=this.findPreviousPanel(newDiv,view);



        //check current view
        var currentView;
        if(anchor){
            currentView=this.findViewTarget(anchor);
        }
        else
            currentView=this.findViewTarget(this.activeDiv);

        //Check if we are dealing with the same view

        var isSplitViewParent=(currentView&&currentView.get(0)!==view.get(0)&&currentView.closest(".splitview").get(0)===view.closest(".splitview").get(0)&&currentView.closest(".splitview").length!==0);
        if(isSplitViewParent){
            newView=false;
        }
        
        $(previous).trigger("panelbeforeunload");
        $(newDiv).trigger("panelbeforeload");
        
        var isNewView=false;
        //check nested views
        if(!isSplitViewParent)
            isSplitViewParent=currentView.parent().closest(".view").length===1;

        if(isSplitViewParent&&currentView&&currentView.get(0)!==view.get(0))
            $(currentView).trigger("nestedviewunload");


        if(!isSplitViewParent&&(newView||currentView&&currentView.get(0)!==view.get(0))){
            //Need to transition the view
            newView=currentView||newView;
            this.runViewTransition(transition,view,newView,newDiv,back);

            this.updateViewHistory(view,newDiv,transition,target);
            isNewView=true;
        }
        else{
            this.runTransition(transition,previous, newDiv, back);
            this.updateViewHistory(view,newDiv,transition,target);
        }

        //Let's check if it has a function to run to update the data
        //Need to call after parsePanelFunctions, since new headers can override
        this.loadContentData(newDiv,view,false,isNewView);

    };
    
    //Override back button behavior.
    $.afui.overrideBackButton = function(overrider) {
    	$(document).off("click", ".backButton, [data-back]");
    	
    	if (overrider === false) {
    		$(document).on("click", ".backButton, [data-back]", function() {
				if ($.afui.useInternalRouting) {
					$.afui.goBack($.afui);
				}
    		});
    	} else if (typeof overrider !== "undefined") {
    		$(document).on("click", ".backButton, [data-back]", overrider);
    	}
    };
   
    //Hide the footer
    //Future: Move css to AFUI
    //Add footer transitions
    $.afui.hideFooter = function(){
    	var activeView = $(this.activeDiv).closest(".view");
    	var activeFooter = activeView.children("footer");
    	activeFooter.addClass("noHeight");
    };
    
    //Hide the footer
    //Future: Move css to AFUI
    $.afui.showFooter = function(){
    	var activeView = $(this.activeDiv).closest(".view");
    	var activeFooter = activeView.children("footer");
    	activeFooter.removeClass("noHeight");
    };
    
};