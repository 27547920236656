"use strict";

module.exports = {
	//General Settings
	// Some of these need to be updated with every release
	currentAppVersionCode: 1,
	lastEulaVersionCode: 1,
	lastMajorSetupVersionCode: 1,
	
	//Debugging:
	enableAnalytics: true,
	analyticsPrefix: "",
	betaTest: false,
	enableLocalLog: false, //Determines if errors are logged locally in _this.errorLog;
	revealPrivateScope: false,
	
	//Analytics
	gaTrackingId: "UA-110816704-1",
};
