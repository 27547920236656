"use strict";

module.exports = function(featureManager, logger) {
	//Future: Determine if callback chains can be reduced by stacking sqlExecution calls.
	//Future: Add version management (http://blog.maxaller.name/2010/03/html5-web-sql-database-intro-to-versioning-and-migrations/)
	//Future: Refactor this. it was done in a hurry.
	//Future: Determine if we need to use a different database API;
	//Future: Move strings, table names, database names, database description, and database version to settings file.
	
	var _this = {};
	
	//Private Properties
	_this.featureManager = featureManager;
	_this.logger = logger;
	_this.database = undefined;
	

	this.loadHighScores = function(successCb, errorCb) {
		//Future: Find a betterway to handle this.
		var returnValue = "undefined";
		var success = false;
		
		var transactionSuccessCb = function() {
			
			if (!_this.featureManager.getFeatureState("database")) {
				
				errorCb();
				return;
			}
			
			if (success !== false) {
				successCb(returnValue);
			} else {
				errorCb();
			}
			
		};
		
		var transactionErrorCb = function(sqlError) {

			if (!_this.featureManager.getFeatureState("database")) {
				errorCb();
				return;
			}

			_this.logger.logError("Database.loadHighScores.transactionErrorCb", {unsanitaryData: sqlError});
			_this.featureManager.disableFeature("database");
			
			errorCb();
		};

		var selectTableErrorCb = function(transaction, sqlError) {
			
			if (!_this.featureManager.getFeatureState("database")) {
				success = false;
				return;
			}

			_this.logger.logError("Database.loadHighScores.selectTableErrorCb", {unsanitaryData: sqlError});
			_this.featureManager.disableFeature("database");
			
			success = false;
		};
		
		var selectRowsErrorCb = function(transaction, sqlError) {

			if (!_this.featureManager.getFeatureState("database")) {
				success = false;
				return;
			}

			_this.logger.logError("Database.loadHighScores.selectRowsErrorCb", {unsanitaryData: sqlError});
			_this.featureManager.disableFeature("database");

			success = false;
		};
		
		var readRowsCb = function (transaction, transactionResults) {
			
			if (!_this.featureManager.getFeatureState("database")) {
				success = false;
				return;
			}
			
			transactionResults = typeof transactionResults !== 'undefined' ? transactionResults : {
				rows: {
					length: 0
				}
			};
			
			var i = 0;
			var l = transactionResults.rows.length;
			var transactionResultRows = [];
			var row = {};
			
			success = true;
			
			while (i < l) {
				row = transactionResults.rows.item(i++);
				transactionResultRows[row.id] = row.data;
			}
			
			if (transactionResultRows.highscores !== "undefined") {
				returnValue = [transactionResultRows.highscores];
			} else {
				returnValue = "undefined";
			}
		};
		
		var selectRowsCb = function (transaction, transactionResults) {
			
			if (!_this.featureManager.getFeatureState("database")) {
				success = false;
				return;
			}
			
			if (transactionResults.rows.length > 0) {
				transaction.executeSql(
					'SELECT * FROM kcrlgamedbdata',
					[],
					readRowsCb,
					selectRowsErrorCb
				);
			} else {
				transaction.executeSql('CREATE TABLE kcrlgamedbdata (id unique, data)'); //If a table does not exist within the database, create it.
				transaction.executeSql('INSERT INTO kcrlgamedbdata (id, data) VALUES ("highscores", ?)', ["undefined"]);
				returnValue = "undefined";
			}
		};
		
		var selectTableCb = function(transaction){
			
			if (!_this.featureManager.getFeatureState("database")) {
				success = false;
				return;
			}
			
			transaction.executeSql(
				'SELECT 1 FROM sqlite_master WHERE type="table" AND name="kcrlgamedbdata" COLLATE NOCASE',
				[],
				selectRowsCb,
				selectTableErrorCb
			);
		};
		
		var loadDatabaseSuccessCb = function(database) {
			if (!_this.featureManager.getFeatureState("database")) {
				errorCb();
				return;
			}
			
			database.transaction(selectTableCb, transactionErrorCb, transactionSuccessCb);
		};
		
		if (!_this.featureManager.getFeatureState("database")) {
			errorCb();
			return;
		}
		
		_this.loadDatabase(loadDatabaseSuccessCb, errorCb);
	};
	
	this.updateHighScores = function(highScores, successCb, errorCb) {
		
		var success = false;
		
		var transactionSuccessCb = function() {
			if (success) {
				successCb();
			} else {
				errorCb();
			}
		};
		
		var transactionErrorCb = function (sqlError) {
			
			if (!_this.featureManager.getFeatureState("database")) {
				errorCb();
				return;
			}

			_this.featureManager.disableFeature("database");
			_this.logger.logError("Database.updateHighScores.transactionErrorCb", {unsanitaryData: sqlError});
			errorCb();
		};
		
		var executeSqlSuccessCb = function (transaction, transactionResults) {
			
			if (!_this.featureManager.getFeatureState("database")) {
				success = false;
				return;
			}
			
			if (transactionResults.rowsAffected < 1) {
				_this.featureManager.disableFeature("database");
				_this.logger.logError("Database.updateHighScores.executeSqlSuccessCb: 0 Rows Affected", {unsanitaryData : transactionResults});
				success = false;
			} else {
				success = true;
			}
		};
		
		var executeSqlErrorCb = function (transaction, sqlError) {
			if (!_this.featureManager.getFeatureState("database")) {
				success = false;
				return;
			}

			_this.featureManager.disableFeature("database");
			_this.logger.logError("Database.updateHighScores.executeSqlErrorCb", {unsanitaryData: sqlError});
			success = false;
		};
		
		var transactionCb = function (transaction) {
			
			if (!_this.featureManager.getFeatureState("database")) {
				success = false;
				return;
			}
			
			transaction.executeSql(
				'UPDATE kcrlgamedbdata SET data = ? WHERE id = ?',
				[highScores, "highscores"],
				executeSqlSuccessCb,
				executeSqlErrorCb
			);
		};
		
		var loadDatabaseSuccessCb = function(database) {
			
			if (!_this.featureManager.getFeatureState("database")) {
				errorCb();
				return;
			}
			
			database.transaction(transactionCb, transactionErrorCb, transactionSuccessCb);
		};
		
		successCb = typeof successCb !== "undefined" ? successCb : function(){};
		errorCb = typeof errorCb !== "undefined" ? errorCb : function(){};
		
		if (!_this.featureManager.getFeatureState("database")) {
			errorCb();
			return;
		}
		
		_this.loadDatabase(loadDatabaseSuccessCb, errorCb);
	};
	
	//creationCb is only called if a database is created; not when a database is loaded.
	_this.loadDatabase = function(successCb, errorCb, creationCb) {
		//Future: Refactor this
		var loggerOptions = {};
		var unknownError;
		
		successCb = typeof successCb !== "undefined" ? successCb : function(){};
		creationCb = typeof creationCb !== "undefined" ? creationCb : function(){};
		errorCb = typeof creationCb !== "undefined" ? errorCb : function(){};
		
		if (!_this.featureManager.getFeatureState("database")) {
			errorCb();
			return;
		}
		
		if(typeof _this.database !== "undefined") {
			successCb(_this.database);
			return;
		}
		
		try {
			_this.database = window.openDatabase("kcrlgamedb", "1.0", "KC Road Injury Database", 1024, creationCb); //If a WebSQL Database exists, load it, otherwise create it. Limit database size to 5mb due to an error that occurs on iOS7, it is unclear if this error occurs when using Cordova, but there is no reason to push our luck.
		} catch(e) {
			if(typeof e !== "undefined") {
				unknownError = e;
			}
		}
		
		if(typeof unknownError !== "undefined") {
			loggerOptions.unsanitaryData = unknownError;
			_this.logger.logError("Database.constructor: Failed to open database.", {unsanitaryData: unknownError});
			_this.featureManager.disableFeature("database");
			errorCb(unknownError);
		} else if(typeof _this.database !== "undefined") {
			successCb(_this.database);
		} else {
			_this.logger.logError("Database.constructor: Failed to open database.");
			_this.featureManager.disableFeature("database");
			errorCb();
		}
		
	}.bind(this);
};
