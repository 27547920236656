"use strict";

(function(window) {
	
	//Future: Consider if anything needs to be moved into the KCRL module or rolled up from other modules. 
	
	var bindPolyfill = require('functionBindPolyfill');
	var dateNowPolyfill = require('dateNowPolyfill');
	var objectAssignPolyfill = require('objectAssignPolyfill');
	var arrayIsArrayPolyfill = require('arrayIsArrayPolyfill');
	var objectCreatePolyfill = require('objectCreatePolyfill');
	var afuiFixes = require('afuiFixes');
	var KcrlGame = require('KcrlGame');
	var moment = require('moment');
	var defaultAppSettings = require('defaultAppSettings');
	var InitManager = require('initManager');
	var appSettings = require('appSettings');
	var Logger = require('Logger');
	var FeatureManager = require('FeatureManager');
	var Database = require('Database');
	var GameLogic = require('GameLogic');
	var ScreenCenter = require('ScreenCenter');
	
	bindPolyfill();
	dateNowPolyfill();
	objectAssignPolyfill();
	arrayIsArrayPolyfill();
	objectCreatePolyfill();
	afuiFixes();
	
	//Future: Every module should set it's own defaults, unless they are explicitly required in main.js
	appSettings = Object.assign(defaultAppSettings, appSettings);
	
	var startTime = Date.now();
	
	//Future: Configure these directly in the settings file, 
	var loggerOptions = {
			localLogState: appSettings.enableLocalLog,
			appVersionCode: appSettings.currentAppVersionCode,
			//Future: These shouldnt have to be inverted.
			gaState: appSettings.enableAnalytics,
			analyticsPrefix: appSettings.analyticsPrefix,
			startTime: startTime,
	};
	
	var logger = new Logger(loggerOptions);
	var featureManager = new FeatureManager(window.$);
	var database = new Database(featureManager, logger);
	var gameLogic = new GameLogic(logger);
	
	var kcrlGameOptions = {
			logger: logger,
			appSettings: appSettings,
			featureManager: featureManager,
			InitManager: InitManager,
			database: database,
			gameLogic: gameLogic,
			ScreenCenter: ScreenCenter
	};
	
	var kcrlGame = new KcrlGame(kcrlGameOptions);
	
	kcrlGame.init();
	
	if(appSettings.revealPrivateScope === true) {
		window.kcrlGame = kcrlGame;
	}
})(window);
