"use strict";

module.exports = function (options) {
	//Future: Remove jQuery where it isn't nessecary.
	//Future: Replace actions that can be made WCAG AA compliant.
	
	/* globals $, Origami, cordova */

	//Private Scope
	var _this = {};

	//Private Classes

	//Public Methods
	this.init = function () { //Automatic Initialization method.
		//Future: Should parts of this be moved to main.js?
		//Future: Should part of this be moved to a config method?
		//Future: Should a public method be provided that starts the object without configuring AFUI?
		//AFUI settings.
		$.afui.autoLaunch=false; //Prevents AFUI from Launching automatically on DOMContentLoaded.
		$.afui.loadDefaultHash = false; //Always start with the default view; don't load a view based on the URL hash.
		$.afui.backButtonText = "Back"; //Force all back buttons to use "Back" as their textual content.
		$.afui.useOSThemes = false; //Allow the OS theme to be overridden.

		//Once App Framework UI is ready, prepare the user interface behind the splash screen. This is done to prevent FOUC.
		$.afui.ready(_this.afuiReadyCb);
		
		window.document.addEventListener("DOMContentLoaded", _this.DOMContentLoadedCb , false);
	};

	//Private Methods

	_this.DOMContentLoadedCb = (function(window, _this, $){return function() {
		var panelLoadCb = (function(_this){ return function(event) {
			var viewId = event.target.id;
			
			_this.logger.logView(viewId);
		};})(_this);
		
		$("#mainView").on("panelload", panelLoadCb);
		
		//Attach FastClick to body.
		window.Origami.fastclick(window.document.body);
		
		//Start AFUI
		$.afui.launch();
	};})(window, _this, $);
	
	//Initialize the app after AFUI and Cordova are ready.
	_this.afterDeviceReady = function () {

		var initManagerCb = function () {
			var disabledFeatureMessages = _this.featureManager.getDisabledFeatureMessages();

			var createCb = function() {
				_this.ScreenCenter = _this.ScreenCenter($,window,"#phaserparent");
				// Prevent AFUI from hijacking click / touch events from phaser.
				$("#phaserparent canvas").attr('data-ignore-pressed', 'true'); 
			};
			
			_this.gameLogic.runGame(highScores, showMenuCb, updateHighScoresCb, createCb);
			
			window.$.afui.showFooter();
			_this.disabledFeaturesPopup(disabledFeatureMessages);

			_this.featureManager.setOptions({
				onDisableCb: featureManagerOnDisableCb,
			});

			_this.toggleUIBlock(false);
		};
		
		var swipeRightCb = function (evt) {
			$.afui.goBack();
		};
		
		var highScores;

		var startGameCb = _this.startGameCb;
		var startDemoCb = _this.startDemoCb;
		var goBackCb = _this.goBackCb;
		var showMenuCb = _this.showMenuCb;
		var updateHighScoresCb = function(){_this.updateDatabase();}.bind(this);

		var supressSubmitCb = _this.supressSubmitCb;

		var devicePlatform = window.device.platform;

		var betaTestPopupOptions;

		//Future: Move this
		var goHome = function () {
			window.$.afui.loadContent("#mainView", false, false, "invoke");
			window.$.afui.clearHistory();
		};

		var notices = function () {

			var noticesCb = function () {
				//Future: If the EULA is updated, it needs to be shown to existing users before analytics are called.
				_this.logger.enable();
				_this.logger.startGoogleAnalytics(window.ga, _this.appSettings.gaTrackingId, 30);
				_this.logger.startFacebookAnalytics(window.facebookConnectPlugin);
				
				//Future: This is a hack to catch the initial view. Rework logger implimentation and then remove it.
				_this.logger.logView("main-menu");
			};

			if (_this.firstLaunch === true) {
				window.$.afui.popup({
					suppressTitle: true,
					message: '<span>The use of the "KC Road Lawyer" game does not create an attorney client relationship. The choice of a lawyer is an important decision and should not be based solely upon advertisements.</span>',
					cancelText: "Got it!",
					cancelOnly: true,
					cancelCallback: noticesCb
				});
			} else {
				noticesCb();
			}
		};

		var initManagerStages = ["loadHighScores"];

		var featureManagerOnDisableCb = function (disabledMessage) {
			_this.disabledFeaturesPopup([disabledMessage]);
		};

		if (devicePlatform === "iOS") {
			$.query("body").addClass("onios");
			//Fix a problem with cordova-plugin-google-analytics. See config.xml for more info.
			window.cordova.plugins.ICPDoNotBackup.setDoNotBackup();
		} else if (devicePlatform === "Android") {
			$.query("body").addClass("onAndroid");
		}
		
		//Go ahead and hide the splashscreen since the UI is finished
		window.navigator.splashscreen.hide();

		//Swiperight to go back.
		// Future: Why is this on window.document instead of #mainView?
		$(window.document).on("swipeRight", "#mainView", swipeRightCb);

		$("#startGame").on("click", startGameCb);
		$("#startDemo").on("click", startDemoCb);

		if (_this.betaTest) {
			betaTestPopupOptions = {
				addCssClass: "errorPopup",
				suppressTitle: true,
				//Future: Don't hard code strings.
				message: '<span>This demo of the "KC Road Lawyer" game is provided for testing purposes only. Please send feedback, suggestions, and bug reports to chris@kcroadlawyers.com</span>',
				cancelText: "Got it!",
				cancelOnly: true
			};

			window.$.afui.popup(betaTestPopupOptions);
		}

		//Future: Determine if this should be fatal.
		_this.featureManager.registerFeature("database", {
			disabledMessage: "The ability to save high scores.",
			uiSelectors: [],
		});

		_this.initManager = new _this.InitManager(initManagerStages, initManagerCb);

		//Future: Refactor this.
		_this.database.loadHighScores(
			(function (_this, notices, window) {
				return function (highScoresJson) {
					if (highScoresJson !== "undefined") {
						highScores = JSON.parse(highScoresJson[0]);
						_this.firstLaunch = false;
					} else {
						_this.firstLaunch = true;
					}
					_this.initManager.completeStage("loadHighScores");
					notices();
				};
			})(_this, notices, window),
			function (e) {
				_this.firstLaunch = true;
				_this.initManager.completeStage("loadHighScores");
				notices();
			}
		);

	}.bind(this);

	//Blocks the UI while dealing with a few AFUI quirks.
	//Future: Consider Moving this into an AFUI plugin
	_this.blockUI = function (opacity, message) {
		//Future: Consider replacing the original function.
		if (typeof message !== "undefined" && message !== null) {
			$.afui.showMask(message);
		}
		$.afui.blockUI(opacity);

		return _this.uiBlocked;
	}.bind(this);

	//Unblocks the UI while dealing with a few AFUI quirks.
	//Future: Consider Moving this into an AFUI plugin
	_this.unblockUI = function () {
		//Future: Consider replacing the original function.
		$.afui.hideMask();
		if (!$.afui.showingPopup) {
			$.afui.unblockUI();
		}

		return $.afui.uiBlocked;
	}.bind(this);

	_this.toggleUIBlock = function (blocked, message, opacity) {
		var uiBlocked = typeof blocked !== "undefined" && blocked !== null ?
			blocked : !$.afui.uiBlocked;
		message = typeof message !== "undefined" ? message : null;
		//Future: Don't hard code opacity default.
		opacity = typeof opacity !== "undefined" && opacity !== null ? opacity : 0.1;

		if (uiBlocked) {
			_this.blockUI(opacity, message);
		} else {
			_this.unblockUI();
		}

		return uiBlocked;
	}.bind(this);

	_this.disabledFeaturesPopup = function (disabledFeatureMessages) {
		var disabledFeatureMessagesLength = disabledFeatureMessages.length;
		var disabledFeatureListTemplate;
		var disabledFeatureList;
		
		if (disabledFeatureMessagesLength > 0) {
			disabledFeatureListTemplate = _this.handlebarsTemplates.disabledFeatureList;
			disabledFeatureList = disabledFeatureListTemplate({
				disabledFeatureMessages: disabledFeatureMessages
			});
			var popupOptions = {
				addCssClass: "errorPopup disabledFeatureListPopup",
				suppressTitle: true,
				message: disabledFeatureList,
				cancelText: "Dismiss",
				cancelOnly: true
			};

			window.$.afui.popup(popupOptions);
		}
	}.bind(this);

	_this.openBrowser = function (url) {
		
		url = encodeURI(url);
		cordova.InAppBrowser.open(encodeURI(url), "_system", "location=yes"); //Launch the website. It should open in the default browser.
	}.bind(this);

	_this.updateDatabase = function () {
		var highScores = _this.gameLogic.getHighScores();
		var highScoresJson = JSON.stringify(highScores);
		
		_this.database.updateHighScores(highScoresJson);
	}.bind(this);

	//Private Properties
	//Future: these be listed as a properties, methods, or somethign else?
	_this.logger = options.logger;
	_this.gameLogic = options.gameLogic;
	_this.featureManager = options.featureManager;
	_this.initManager = undefined;
	_this.InitManager = options.InitManager;
	_this.screenCenter = undefined;
	_this.ScreenCenter = options.ScreenCenter;
	_this.database = options.database;
	_this.lastClick = Date.now();
	_this.firstLaunch =  undefined;

	//General settings
	//Variables used for debugging:
	_this.appSettings = options.appSettings;
	_this.betaTest = _this.appSettings.betaTest;
	_this.revealPrivateScope = _this.appSettings.revealPrivateScope;

	//General app settings (Some of these need to be updated with every release):
	_this.currentAppVersionCode = _this.appSettings.currentAppVersionCode;
	_this.lastEulaVersionCode = _this.appSettings.lastEulaVersion;
	_this.lastMajorSetupVersionCode = _this.appSettings.lastMajorSetupVersionCode;

	//Private Callbacks.
	// Future: Some of these should be broken into private methods that are invoked by a callback.
	// Future: Determine if some of these callbacks can be broken out of closures.
	// Future: Some of these should be rolleddown into other methods and callbacks.
	_this.afuiReadyCb = (function (toggleUIBlock, $) {
		return function () {
			
			//Future: Don't hard code strings
			toggleUIBlock(true, "Initializing...");
			window.document.addEventListener("deviceready", _this.devicereadyCb, false); //Delay further execution until Cordova fires the deviceready event.
		};
	})(_this.toggleUIBlock.bind(this), $);

	_this.devicereadyCb = _this.afterDeviceReady.bind(this);

	_this.goBackCb = (function (afui) {
		return function () {
			afui.goBack();
		};
	})($.afui);

	_this.supressSubmitCb = function (event) {
		
		event.preventDefault();
	};

	_this.startGameCb = (function (window, _this) {
		return function () {
			
			_this.logger.logView("game-main");
			
			//Future: Slide menu off screen
			$("#mainView").addClass("displayNone");
			
			_this.gameLogic.startGame();
		};
	})(window, _this);

	_this.startDemoCb = (function (window, _this) {
		return function () {
			
			_this.logger.logView("game-demo");
			
			//Future: Slide menu off screen
			$("#mainView").addClass("displayNone");
			
			_this.gameLogic.startDemo();
		};
	})(window, _this);
	
	_this.showMenuCb = (function (window, _this) {
		return function () {
			$("#mainView").removeClass("displayNone");
		};
	})(window, _this);

	//Constructor
	//Future: Constructors should be in a private scope.
	
	//Future: Port this to all modules.
	if (_this.revealPrivateScope) {
		this._this = _this;
	}
};
