"use strict";

module.exports = function(stages, cb) {
	//Future: Rework return values.
	//Future: Refactor this, it was written in a hurry.
	//Future: Add ability to suspend callback execution untill the caller is done adding stages.
	//Future: Add addStages and registerStages methods.
	
	//Private Scope
	var _this = {};
	
	this.registerStage = function(name, required, state) {
		_this.addStage(name, required, state);
		
		return this.checkStages();
	};
	
	this.checkStages = function() {
		
		var requirements = _this.requirements;
		var l = requirements.length;
		var count = 0;
		var stageItem;

		for(var i = 0; i < l; i++) {
			stageItem = requirements[i];
			
			if (stageItem.state === true) {
				count++;
			}
		}
		
		if (count === l && !_this.done) {
			_this.done = true;
			_this.cb();
		}
		
		return count;
	};
	
	this.completeStage = function(name) {
		_this.stagesByName[name].state = true;
		
		return this.checkStages();
	};
	
	this.checkStage = function(name) {
		if (name in _this.stagesByName) {
			return _this.stagesByName[name].state;
		}
		
		return undefined;
	};
	
	this.getStages = function() {
		return _this.stagesByName;
	};
	
	this.getStage = function(name) {
		if (name in _this.stagesByName) {
			return _this.stagesByName[name];
		}
		
		return undefined;
	};
	
	_this.addStage = function(name, required, state){

		var stateItem = new _this.InitStage(name, required, state);
		
		_this.stagesByName[name] = stateItem;
		
		if (required === true) {
			_this.requirements.push(stateItem);
		}
		
		return;
	};
	
	_this.InitStage = require("InitStage");
	_this.stagesByName = {};
	_this.requirements = [];
	_this.done = false;
	_this.cb = typeof cb !== "undefined" ? cb : function(){};
	
	//Constructor
	stages = Array.isArray(stages) ? stages : [];
	var l = stages.length;
	var defaultStage = {
			required: true,
			state: false
	};
	
	var stage;
	
	for(var i = 0; i < l; i++) {
		stage = stages[i];
		
		//Future: break string detection in to a module, since it is non-obvious.
		if (typeof stage === "string" || stage instanceof String) {
			stage = {name: stage};
		}
		
		stage = Object.assign(defaultStage, stage);
		_this.addStage(stage.name, stage.required, stage.state);
	}
	
	this.checkStages();
};