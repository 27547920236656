"use strict";

//Future: Rework module

module.exports = function($, window, centeredElementId) {
	//Future: Convert callbacks to closures or private methods.
	//Future: Handle multiple elements with one object.

	//Private Scope
	var _this = {};

	_this.delayedCheckOrientationCb = function() {
		_this.delayedCheckOrientationCount++;

		if (_this.delayedCheckOrientationCount >= 200 && typeof _this.delayIntervalId !== "undefined") {
			clearInterval(_this.delayIntervalId);
			_this.delayIntervalId = undefined;
			_this.delayedCheckOrientationCount = 0;
		}

		_this.checkOrientation();

	}.bind(this);

	_this.orientationchangeCb = function() {
		if (!_this.checkOrientation() && typeof _this.delayIntervalId !== "undefined") {
			//If this point is reached, we are on Android or an OS that needs the same behavior.
			_this.delayIntervalId = setInterval(_this.delayedCheckOrientationCb, 10);
		}
	}.bind(this);

	_this.checkOrientation = function() {
		_this.documentBodyClientWidth = document.body.clientWidth;
		_this.documentBodyClientHeight = document.body.clientHeight;

		if (_this.documentBodyClientHeight === _this.oldDocumentBodyClientHeight && _this.documentBodyClientWidth === _this.oldDocumentBodyClientWidth) {
			return false;
		}

		if (typeof _this.delayIntervalId !== "undefined") {
			clearInterval(_this.delayIntervalId);
			_this.delayIntervalId = undefined;
			_this.delayedCheckOrientationCount = 0;
		}

		_this.oldDocumentBodyClientHeight = _this.documentBodyClientHeight;
		_this.oldDocumentBodyClientWidth = _this.documentBodyClientWidth;

		_this.positionCenteredElement();
	}.bind(this);

	_this.positionCenteredElement = function() {
		var centeredElementClientHeight = _this.centeredElement[0].clientHeight;
		var centeredElementCssTop = Math.round(((_this.documentBodyClientHeight / 2) + window.pageYOffset) - (centeredElementClientHeight / 2));

		if (centeredElementCssTop < 0) {
			centeredElementCssTop = 0;
		}

		var centeredElementCssTopPx = centeredElementCssTop + "px";

		_this.centeredElement.css("top", centeredElementCssTop);
		_this.centeredElement.css("left", (document.body.clientWidth / 2) - (_this.centeredElement[0].clientWidth / 2) + "px");
		_this.centeredElement.css("position", "fixed");

		if (_this.documentBodyClientHeight < centeredElementClientHeight) {
			_this.$("BODY").css('overflow', 'auto');
		}
	}.bind(this);

	_this.delayIntervalId = undefined;
	_this.$ = $;
	_this.window = _this.$(window);
	_this.documentBodyClientWidth = document.body.clientWidth;
	_this.documentBodyClientHeight = document.body.clientHeight;
	_this.oldDocumentBodyClientWidth = _this.documentBodyClientWidth;
	_this.oldDocumentBodyClientHeight = _this.documentBodyClientHeigh;
	_this.delayedCheckOrientationCount = 0;
	_this.centeredElement = _this.$(centeredElementId);

	_this.positionCenteredElement();

	_this.window.bind("resize", _this.orientationchangeCb);

	if ("onorientationchange" in window) {
		_this.window.bind("orientationchange", _this.orientationchangeCb);
	}

};
