"use strict";

module.exports = function (logger) {
	/* globals Phaser */

	//Private Scope
	var _this = {};
	
	var self = this;
	
	function preload() {
		var i;
		var l;
		_this.game.clearBeforeRender = false;
		_this.game.scale.fullScreenScaleMode = Phaser.ScaleManager.SHOW_ALL;
		_this.game.scale.scaleMode = Phaser.ScaleManager.SHOW_ALL;
		_this.game.scale.refresh();

		_this.game.load.image("lifeIcon", "img/thekcroadlawyerlife.png");
		_this.game.load.image("background", "img/background.png");
		_this.game.load.image("courthouse", "img/courthouse.png");
		_this.game.load.image("apartment", "img/apartment.png");
		_this.game.load.spritesheet("car", "img/kcrlcar.png", 178, 100);
		_this.game.load.spritesheet("thekcroadlawyer", "img/thekcroadlawyer.png", 52, 100);
		_this.game.load.spritesheet("client", "img/clients.png", 52, 100);
		_this.game.load.spritesheet("zombie", "img/kcrlzombies.png", 64, 100);
		
		_this.game.load.audio('easy', ['audio/mainthemeeasy.mp3', 'audio/mainthemeeasy.ogg']);
		_this.game.load.audio('medium', ['audio/mainthememedium.mp3', 'audio/mainthememedium.ogg']);
		_this.game.load.audio('hard', ['audio/mainthemehard.mp3', 'audio/audio/mainthemehard.ogg']);
		_this.game.load.audio('impossible', ['audio/mainthemeimpossible.mp3', 'audio/mainthemeimpossible.ogg']);
		_this.game.load.audio('intro', ['audio/intro.mp3', 'audio/intro.ogg']);
		_this.game.load.audio('game over', ['audio/gameover.mp3', 'audio/gameover.ogg']);
		
		_this.game.load.audio('door', ['audio/door.mp3', 'audio/door.ogg']);
		_this.game.load.audio('explosion', ['audio/explosion.mp3', 'audio/explosion.ogg']);
		_this.game.load.audio('zombie', ['audio/zombie.mp3', 'audio/audio/zombie.ogg']);

		i = 0;
		l = _this.obstacleImages.length;
		while (i < l) {
			_this.game.load.image(_this.obstacleImages[i], "img/" + _this.obstacleImages[i] + ".png");
			i++;
		}
		
		i = 0;
		l = _this.bgOverlayImages.length;
		while (i < l) {
			_this.game.load.image(_this.bgOverlayImages[i], "img/" + _this.bgOverlayImages[i] + ".png");
			i++;
		}
	}

	function create() {
		var i;
		var unitCount = _this.railCount * 3 + 1;
		var testText;
		var chromeFix;
		
		Phaser.Canvas.setImageRenderingCrisp(_this.game.canvas);

		_this.worldWidth = Math.floor(_this.game.world.width);
		_this.worldHeight = Math.floor(_this.game.world.height);
		_this.playAreaHeight = Math.floor((_this.worldHeight / 8) * 6);
		_this.playAreaOffset = _this.worldHeight - _this.playAreaHeight;

		_this.halfUnit = Math.floor(_this.playAreaHeight / unitCount);

		_this.fullUnit = _this.halfUnit * 2;

		_this.quarterUnit = Math.floor(_this.halfUnit / 2);
		
		_this.unitScale = _this.fullUnit / 100;

		_this.horizontalSpawnPositionOffset = 0;
		_this.verticalSpawnPositionOffset = 0;
		_this.playerOffset = _this.halfUnit;
		_this.demoNpcSpacing = _this.fullUnit * 2;
		_this.scoreRightOffset = _this.worldWidth - _this.fullUnit;
		_this.scoreTopOffset = _this.halfUnit;

		
		_this.spawnPositionRight = _this.worldWidth + _this.horizontalSpawnPositionOffset;
		_this.spawnPositionLeft = 0 - _this.horizontalSpawnPositionOffset;
		_this.spawnPositionTop = 0 - _this.verticalSpawnPositionOffset;
		
		// Future: Determine if the constants (+2, +1) are needed.
		_this.boundsWidth = Math.ceil(_this.game.world.width) + _this.horizontalSpawnPositionOffset * 2 + 2;
		_this.boundsHeight = Math.ceil(_this.game.world.height) + 1 - _this.spawnPositionTop;
		_this.leftBounds = _this.spawnPositionLeft - 1;
		_this.rightBounds = _this.spawnPositionRight + 1;
		_this.topBounds = _this.spawnPositionTop - 1;
		_this.bottomBounds = _this.topBounds + _this.boundsHeight;

		_this.playerSpeed = _this.playAreaHeight / _this.playerSpeedSeconds;
		_this.initDriveSpeed = _this.spawnPositionRight / _this.initDriveSpeedSeconds;
		_this.maxDriveSpeed = _this.spawnPositionRight / _this.maxDriveSpeedSeconds;
		_this.zombieSpeed = _this.initDriveSpeed * _this.zombieSpeedPercentage;
		_this.deathSpeed = _this.initDriveSpeed * _this.deathSpeedPercentage;

		_this.sceneNpcSpeed = (_this.playAreaHeight / 2 - _this.halfUnit + _this.playAreaOffset) / _this.sceneNpcSpeedSeconds;

		_this.driveSpeedIncrease = (_this.maxDriveSpeed - _this.initDriveSpeed) / _this.maxDriveSpeedDifficulty;
		_this.waveLengthDecrease = (_this.initWaveLength - _this.minWaveLength) / _this.minWaveLengthDifficulty;

		_this.roadBg = _this.game.add.tileSprite(0, 0, _this.worldWidth, _this.worldHeight, "background");
		_this.roadBg.tileScale.x = _this.worldHeight / _this.game.cache.getImage("background").height;
		_this.roadBg.tileScale.y = _this.roadBg.tileScale.x;

		_this.game.physics.startSystem(Phaser.Physics.ARCADE);

		_this.game.physics.arcade.gravity.y = 0;

		_this.game.physics.setBoundsToWorld(_this.leftBounds, _this.topBounds, _this.boundsWidth, _this.boundsHeight);
		
		
		//Future: Add messages, such as game over text, and the background to layers
		
		_this.entities = _this.game.add.group();
			
			_this.actors = _this.game.add.group();
			_this.actors.enableBody = true;
			_this.entities.add(_this.actors);
	
				// Future: Add scene NPCs to a child group.
				_this.npcs = _this.game.add.group();
				_this.npcs.enableBody = true;
				_this.actors.add(_this.npcs);
				
					_this.obstacles = _this.game.add.group();
					_this.obstacles.enableBody = true;
					_this.npcs.add(_this.obstacles);
					
					_this.zombies = _this.game.add.group();
					_this.zombies.enableBody = true;
					_this.npcs.add(_this.zombies);
				
				_this.playerGroup = _this.game.add.group();
				_this.playerGroup.enableBody = true;
				_this.actors.add(_this.playerGroup);
				
				_this.buildings = _this.game.add.group();
				_this.buildings.enableBody = true;
				_this.actors.add(_this.buildings);
					
				// Future: Place score in to this.
				_this.uiElements = _this.game.add.group();
				_this.entities.add(_this.uiElements);
				
					_this.lifeIcons = _this.game.add.group();
					_this.uiElements.add(_this.lifeIcons);
		
		
		_this.gameTimer = _this.game.time.create(false);
		_this.gameTimer.start(0);

		i = 0;
		while (i < _this.railCount) {
			_this.rails.push(_this.playAreaOffset + _this.halfUnit * (i * 3 + 1));
			i++;
		}
		
		//Future: This is a hack. Fix it.
		testText = _this.game.add.text(_this.worldWidth, _this.worldHeight, "Your clients still have to show up for court.", {
			font: _this.fullUnit + "px 'Permanent Marker'"
		});
		_this.textScaling = (_this.worldWidth * 0.75) / testText.width;
		_this.fontSize = Math.floor(_this.fullUnit * _this.textScaling) + "px 'Permanent Marker'";
		testText.destroy();
		
		testText = _this.game.add.text(_this.worldWidth, _this.worldHeight, "\n\nHigh Scores\n\n00000\n\n00000\n\n00000\n\n00000\n\n00000\n\n", {
			font: _this.fontSize
		});
		_this.highScoreTextScaling = (_this.worldHeight * 0.75) / testText.height;
		_this.highScoreTextScaling = _this.highScoreTextScaling < _this.textScaling ? _this.highScoreTextScaling : _this.textScaling;
		_this.highScoreFontSize = Math.floor(_this.fullUnit * _this.highScoreTextScaling) + "px 'Permanent Marker'";
		testText.destroy();
		
		_this.scoreText = _this.game.add.text(0, _this.scoreTopOffset, "00000", {
			font: _this.halfUnit + "px Arial",
			fill: "#FFF"
		});
		_this.scoreText.x = _this.scoreRightOffset - _this.scoreText.width;
		
		chromeFix = function(){_this.game.sound.context.resume();};
		
		_this.game.input.onDown.addOnce(chromeFix);
		
		playMusic("intro");
		
		_this.createCb();

	}

	function mainMenuScene() {

		_this.updateFunction = null;
		_this.gameTimer.removeAll();
		
		playMusic("intro");

		_this.showMenuCb();
	}

	function startGame() {
		
		setLives(_this.initLives);

		_this.level = 1;
		setScore(0);

		_this.driveDirection = _this.level % 2 ? _this.RIGHT : _this.LEFT;

		introScene();
	}

	function introScene() {
		_this.gameTimer.removeAll();
		_this.updateFunction = "introUpdater";

		clearNpcs();
		clearOverlayBgs();

		_this.theKcRoadLawyer = _this.npcs.create(0, 0, "thekcroadlawyer");

		createPlayer();
		
		playMusic("easy");


		_this.player.animations.play("stopRight");

		//Future: Improve how this is handled.
		_this.theKcRoadLawyer.scale.setTo(_this.unitScale);
		_this.theKcRoadLawyer.animations.add("thekcroadlawyer", [0, 1, 2], 5, true);
		_this.theKcRoadLawyer.animations.play("thekcroadlawyer");
		_this.theKcRoadLawyer.x = _this.player.x + _this.player.width / 2 - _this.theKcRoadLawyer.width / 2;
		_this.theKcRoadLawyer.y = _this.spawnPositionTop - _this.theKcRoadLawyer.height;
		_this.theKcRoadLawyer.body.velocity.y = _this.sceneNpcSpeed;

		_this.roadBg.autoScroll(0, 0);
	}

	function startLevel() {

		_this.wave = 0;

		_this.driveDirection = _this.level % 2 ? _this.RIGHT : _this.LEFT;

		createPlayer();

		_this.updateFunction = "mainUpdater";

		_this.levelDifficulty = _this.difficultyIncreasePerLevel * (_this.level - 1);
		_this.wavesInLevel = _this.initWavesInLevel + _this.wavesIncreasePerLevel * (_this.level - 1);

		updateDifficulty();

		_this.gameTimer.add(_this.waveLength, zombiePhase, self, null);
		
		if (_this.level === 2){
			playMusic("medium");
		} else if (_this.level === 3) {
			playMusic("hard");
		} else if (_this.level === 4) {
			playMusic("impossible");
		}

		startOverlayBg();
	}

	function finishLevel() {

		_this.level += 1;

		if (_this.driveDirection === _this.RIGHT) {
			pickupClientSceneStageZero();
		} else {
			dropoffClientSceneStageZero();
		}

	}

	function pickupClientSceneStageZero() {
		_this.gameTimer.removeAll();
		_this.updateFunction = "pickupClientStageZeroUpdater";
		
		//Future: Improve how this is handled.
		_this.apartment = _this.buildings.create(0, 0, "apartment");
		_this.apartment.scale.setTo(_this.unitScale);

		_this.apartment.x = _this.spawnPositionRight;
		_this.apartment.y = 0;
		_this.apartment.body.velocity.x = 0 - _this.driveSpeed;


		clearNpcs();
		clearOverlayBgs();

		_this.player.body.velocity.y = 0;
		_this.player.body.velocity.x = 0;
	}
	
	function pickupClientSceneStageOne() {
		
		_this.gameTimer.removeAll();
		_this.updateFunction = "pickupClientStageOneUpdater";

		clearNpcs();
		clearOverlayBgs();

		_this.roadBg.autoScroll(0, 0);
		
		//Future: Improve how this is handled.
		destroyEntity(_this.apartment);
		_this.apartment = _this.buildings.create(0, 0, "apartment");
		_this.apartment.scale.setTo(_this.unitScale);
		
		_this.apartment.x = _this.worldWidth - _this.playerOffset - _this.player.width + _this.player.width / 2 - _this.apartment.width / 2;
		_this.apartment.y = 0;
		_this.apartment.checkWorldBounds = true;
		_this.apartment.events.onOutOfBounds.add(destroyEntity, self);
		
		_this.player.body.collideWorldBounds = false;
		_this.player.body.velocity.x = _this.driveSpeed;
	}

	function pickupClientSceneStageTwo() {
		_this.updateFunction = "pickupClientStageTwoUpdater";

		createPlayer();
		_this.player.body.collideWorldBounds = false;
		_this.player.checkWorldBounds = false;

		_this.player.x = _this.spawnPositionRight;

		_this.player.body.velocity.x = 0 - _this.driveSpeed;

		_this.player.animations.play("left");
	}

	function pickupClientSceneStageThree() {
		_this.updateFunction = "pickupClientStageThreeUpdater";

		//Future: Improve how this is handled.
		_this.client = _this.npcs.create(0, 0, "client");
		_this.client.scale.setTo(_this.unitScale);
		_this.client.animations.add("clientDown", [0, 1, 2], 5, true);
		_this.client.animations.play("clientDown");

		destroyEntity(_this.player);
		createPlayer();
		_this.player.x = _this.worldWidth - _this.playerOffset - _this.player.width;
		_this.player.animations.play("stopLeft");

		_this.client.x = _this.player.x + _this.player.width / 2 - _this.client.width / 2;
		_this.client.y = _this.spawnPositionTop - _this.client.height;
		
		_this.client.body.velocity.y = _this.sceneNpcSpeed;
	}
	
	function pickupClientSceneStageFour() {
		var roadBgScrollSpeed;
		
		_this.updateFunction = "pickupClientStageFourUpdater";
		
		roadBgScrollSpeed = _this.driveSpeed * (_this.game.cache.getImage("background").height / _this.worldHeight);
		_this.roadBg.autoScroll(roadBgScrollSpeed, 0);
		
		_this.player.animations.play("left");
		
		_this.apartment.body.velocity.x = _this.driveSpeed;
	}
	
	function dropoffClientSceneStageZero() {
		_this.gameTimer.removeAll();
		_this.updateFunction = "dropoffClientStageZeroUpdater";
		
		//Future: Improve how this is handled.
		_this.courtHouse = _this.buildings.create(0, 0, "courthouse");
		_this.courtHouse.scale.setTo(_this.unitScale);

		_this.courtHouse.x = _this.spawnPositionLeft - _this.courtHouse.width;
		_this.courtHouse.y = 0;
		_this.courtHouse.body.velocity.x = _this.driveSpeed;


		clearNpcs();
		clearOverlayBgs();

		_this.player.body.velocity.y = 0;
		_this.player.body.velocity.x = 0;
	}

	function dropoffClientSceneStageOne() {
		
		_this.gameTimer.removeAll();
		_this.updateFunction = "dropoffClientStageOneUpdater";

		_this.roadBg.autoScroll(0, 0);
		
		//Future: Improve how this is handled.
		destroyEntity(_this.courtHouse);
		_this.courtHouse = _this.buildings.create(0, 0, "courthouse");
		_this.courtHouse.scale.setTo(_this.unitScale);
		
		_this.courtHouse.x = _this.playerOffset + _this.player.width / 2 - _this.courtHouse.width / 2;
		_this.courtHouse.y = 0;
		_this.courtHouse.checkWorldBounds = true;
		_this.courtHouse.events.onOutOfBounds.add(destroyEntity, self);
		
		_this.player.body.collideWorldBounds = false;
		_this.player.body.velocity.x = 0 - _this.driveSpeed;
	}

	function dropoffClientSceneStageTwo() {
		_this.updateFunction = "dropoffClientStageTwoUpdater";

		createPlayer();
		_this.player.body.collideWorldBounds = false;
		_this.player.checkWorldBounds = false;

		_this.player.x = _this.spawnPositionLeft - _this.player.width;

		_this.player.body.velocity.x = _this.driveSpeed;

		_this.player.animations.play("right");
	}

	function dropoffClientSceneStageThree() {
		_this.updateFunction = "dropoffClientStageThreeUpdater";

		//Future: Improve how this is handled.
		_this.client = _this.npcs.create(0, 0, "client");
		_this.client.scale.setTo(_this.unitScale);
		_this.client.animations.add("clientUp", [3, 4, 5], 5, true);
		_this.client.animations.play("clientUp");
		_this.client.checkWorldBounds = true;
		_this.client.events.onOutOfBounds.add(destroyEntity, self);

		destroyEntity(_this.player);
		createPlayer();
		_this.player.x = _this.playerOffset;
		_this.player.animations.play("stopRight");

		_this.client.x = _this.player.x + _this.player.width / 2 - _this.client.width / 2;
		_this.client.y = _this.playAreaHeight / 2 + _this.playAreaOffset - _this.quarterUnit * 3;

		_this.client.body.velocity.y = 0 - _this.sceneNpcSpeed;
	}
	
	function dropoffClientSceneStageFour() {
		var roadBgScrollSpeed;
		
		_this.updateFunction = "dropoffClientStageFourUpdater";
		
		roadBgScrollSpeed = 0 - _this.driveSpeed * (_this.game.cache.getImage("background").height / _this.worldHeight);
		_this.roadBg.autoScroll(roadBgScrollSpeed, 0);
		
		_this.player.animations.play("right");
		
		_this.courtHouse.body.velocity.x = 0 - _this.driveSpeed;
	}

	function demoSceneStageOne() {
		var roadBgScrollSpeed;
		var onDownCb = function () {
			destroyEntity(_this.mainText);
			mainMenuScene();
		};

		var inputTimerCb = function () {
			_this.demoOnDown = _this.game.input.onDown.addOnce(onDownCb);
		};

		var demoTimerACb = function () {
			destroyEntity(_this.mainText);

			_this.mainText = _this.game.add.text(_this.game.world.centerX, _this.game.world.centerY, "Your clients still have to show up for court.", {
				font: _this.fontSize,
				fill: "#830303",
				align: "center"
			});
			
			_this.mainText.x = Math.floor(_this.worldWidth / 2) - Math.floor(_this.mainText.width / 2);
			
			_this.gameTimer.add(_this.demoSceneDelay, demoTimerBCb, self, null);
		};
		
		var demoTimerBCb = function () {
			destroyEntity(_this.mainText);

			_this.mainText = _this.game.add.text(_this.game.world.centerX, _this.game.world.centerY, "Leave it to the KC Road Lawyer!", {
				font: _this.fontSize,
				fill: "#830303",
				align: "center"
			});
			
			_this.mainText.x = Math.floor(_this.worldWidth / 2) - Math.floor(_this.mainText.width / 2);
			
			_this.gameTimer.add(_this.demoSceneDelay, demoTimerCCb, self, null);
		};
		
		var demoTimerCCb = function () {
			destroyEntity(_this.mainText);

			_this.player.body.velocity.y = 0 - _this.playerSpeed;
			_this.updateFunction = "demoStageOneUpdater";

			_this.mainText = _this.game.add.text(_this.game.world.centerX, _this.game.world.centerY, "Run over zombies for points!", {
				font: _this.fontSize,
				fill: "#830303",
				align: "center"
			});
			_this.mainText.x = Math.floor(_this.worldWidth / 2) - Math.floor(_this.mainText.width / 2);
		};

		destroyEntity(_this.player);

		_this.gameTimer.removeAll();
		_this.updateFunction = null;

		clearNpcs();
		clearOverlayBgs();

		setScore(0);
		setLives(3);

		createPlayer();
		_this.player.x = _this.playerOffset;
		_this.player.animations.play("right");

		roadBgScrollSpeed = 0 - _this.initDriveSpeed * (_this.game.cache.getImage("background").height / _this.worldHeight);
		_this.roadBg.autoScroll(roadBgScrollSpeed, 0);

		_this.mainText = _this.game.add.text(_this.game.world.centerX, _this.game.world.centerY, "The zombie apocalypse has arrived.", {
			font: _this.fontSize,
			fill: "#830303",
			align: "center"
		});
		
		_this.mainText.x = Math.floor(_this.worldWidth / 2) - Math.floor(_this.mainText.width / 2);
		
		playMusic("easy");

		_this.gameTimer.add(_this.sceneInputDelay, inputTimerCb, self, null);
		_this.gameTimer.add(_this.demoSceneDelay, demoTimerACb, self, null);
	}

	function demoSceneStageTwo() {
		var demoTimerCb = function () {
			var zombie;
			var i;
			var spriteAnimation;
			var railPosition = _this.rails[0];

			destroyEntity(_this.mainText);

			i = 0;
			while (i < 3) {
				spriteAnimation = i * 3;
				zombie = _this.zombies.create(0, 0, "zombie");
				zombie.scale.setTo(_this.unitScale);

				zombie.x = _this.worldWidth + _this.demoNpcSpacing * (i + 1);
				zombie.y = railPosition;
				zombie.body.velocity.x -= _this.initDriveSpeed + _this.zombieSpeed;

				zombie.animations.add("walk", [spriteAnimation, spriteAnimation + 1, spriteAnimation + 2], 5, true);
				zombie.animations.play("walk");

				i++;
			}

			_this.updateFunction = "demoStageTwoUpdater";
		};

		_this.player.body.velocity.y = 0;

		_this.updateFunction = null;

		_this.gameTimer.add(_this.demoSceneDelay, demoTimerCb, self, null);
	}

	function demoSceneStageThree() {
		_this.updateFunction = "demoStageThreeUpdater";

		_this.player.body.velocity.y = _this.playerSpeed;

		_this.mainText = _this.game.add.text(_this.game.world.centerX, _this.game.world.centerY, "Dodge Obstacles!", {
			font: _this.fontSize,
			fill: "#830303",
			align: "center"
		});
		_this.mainText.x = Math.floor(_this.worldWidth / 2) - Math.floor(_this.mainText.width / 2);
	}

	function demoSceneStageFour() {
		var demoTimerCb = function () {
			var obstacle;
			var i;
			var railPosition = _this.rails[3];

			destroyEntity(_this.mainText);

			i = 0;
			while (i < 3) {
				obstacle = _this.obstacles.create(0, 0, _this.obstacleImages[i]);
				obstacle.scale.setTo(_this.unitScale);
				obstacle.x = _this.worldWidth + _this.demoNpcSpacing * (i + 1);
				obstacle.y = railPosition;
				obstacle.body.velocity.x -= _this.initDriveSpeed;

				i++;
			}

			_this.updateFunction = "demoStageFourUpdater";
		};

		_this.player.body.velocity.y = 0;

		_this.updateFunction = null;

		_this.gameTimer.add(_this.demoSceneDelay, demoTimerCb, self, null);
	}

	function demoSceneStageFive() {
		_this.updateFunction = "demoStageFiveUpdater";

		_this.player.body.collideWorldBounds = false;
		_this.player.animations.stop();
		_this.player.body.velocity.x = 0 - _this.deathSpeed;
	}

	function update() {
		var i;
		var l;
		var entityToDestroy;

		//Future: Improve how this is handled
		if (_this.updateFunction === "mainUpdater") {
			mainUpdater();
		} else if (_this.updateFunction === "introUpdater") {
			introUpdater();
		} else if (_this.updateFunction === "pickupClientStageZeroUpdater") {
			pickupClientStageZeroUpdater();
		} else if (_this.updateFunction === "pickupClientStageOneUpdater") {
			pickupClientStageOneUpdater();
		} else if (_this.updateFunction === "pickupClientStageTwoUpdater") {
			pickupClientStageTwoUpdater();
		} else if (_this.updateFunction === "pickupClientStageThreeUpdater") {
			pickupClientStageThreeUpdater();
		} else if (_this.updateFunction === "pickupClientStageFourUpdater") {
			pickupClientStageFourUpdater();
		} else if (_this.updateFunction === "dropoffClientStageZeroUpdater") {
			dropoffClientStageZeroUpdater();
		} else if (_this.updateFunction === "dropoffClientStageOneUpdater") {
			dropoffClientStageOneUpdater();
		} else if (_this.updateFunction === "dropoffClientStageTwoUpdater") {
			dropoffClientStageTwoUpdater();
		} else if (_this.updateFunction === "dropoffClientStageThreeUpdater") {
			dropoffClientStageThreeUpdater();
		} else if (_this.updateFunction === "dropoffClientStageFourUpdater") {
			dropoffClientStageFourUpdater();
		} else if (_this.updateFunction === "demoStageOneUpdater") {
			demoStageOneUpdater();
		} else if (_this.updateFunction === "demoStageTwoUpdater") {
			demoStageTwoUpdater();
		} else if (_this.updateFunction === "demoStageThreeUpdater") {
			demoStageThreeUpdater();
		} else if (_this.updateFunction === "demoStageFourUpdater") {
			demoStageFourUpdater();
		} else if (_this.updateFunction === "demoStageFiveUpdater") {
			demoStageFiveUpdater();
		}

		i = 0;
		l = _this.destructionArray.length;

		while (i < l) {
			entityToDestroy = _this.destructionArray.pop();

			// Future: Determine why this check is needed and try to eliminate it.
			if (typeof entityToDestroy !== "undefined") {
				entityToDestroy.destroy();
			}

			i++;
		}
	}

	function introUpdater() {
		if (_this.theKcRoadLawyer.y + _this.quarterUnit * 3 >= _this.playAreaHeight / 2 + _this.playAreaOffset) {
			destroyEntity(_this.theKcRoadLawyer);
			playEffect("door");
			startLevel();
		}
	}

	function pickupClientStageZeroUpdater() {
		if (_this.apartment.x <= _this.worldWidth - _this.playerOffset - _this.player.width + _this.player.width / 2 - _this.apartment.width / 2) {
			pickupClientSceneStageOne();
		}
	}
	
	function pickupClientStageOneUpdater() {
		if (_this.player.alive === false) {
			pickupClientSceneStageTwo();
		}
	}

	function pickupClientStageTwoUpdater() {
		if (_this.player.x <= _this.worldWidth - _this.playerOffset - _this.player.width) {
			pickupClientSceneStageThree();
		}
	}

	function pickupClientStageThreeUpdater() {
		if (_this.client.y + _this.quarterUnit * 3 >= _this.playAreaHeight / 2 + _this.playAreaOffset) {
			playEffect("door");
			destroyEntity(_this.client);
			pickupClientSceneStageFour();
		}
	}
	
	function pickupClientStageFourUpdater() {
		if (_this.apartment.alive === false) {
			destroyEntity(_this.apartment);
			startLevel();
		}
	}
	
	function dropoffClientStageZeroUpdater() {
		if (_this.courtHouse.x >= _this.playerOffset + _this.player.width / 2 - _this.courtHouse.width / 2) {
			dropoffClientSceneStageOne();
		}
	}

	function dropoffClientStageOneUpdater() {
		if (_this.player.alive === false) {
			dropoffClientSceneStageTwo();
		}
	}

	function dropoffClientStageTwoUpdater() {
		if (_this.player.x >= _this.playerOffset) {
			dropoffClientSceneStageThree();
		}
	}

	function dropoffClientStageThreeUpdater() {
		if (_this.client.alive === false) {
			destroyEntity(_this.client);
			updateScore(_this.scorePerClientDrop);
			dropoffClientSceneStageFour();
		}
	}
	
	function dropoffClientStageFourUpdater() {
		if (_this.courtHouse.alive === false) {
			destroyEntity(_this.courtHouse);
			startLevel();
		}
	}

	function demoStageOneUpdater() {
		if (_this.player.y <= _this.rails[0]) {
			demoSceneStageTwo();
		}
	}

	function demoStageTwoUpdater() {
		_this.game.physics.arcade.overlap(_this.player, _this.zombies, hitZombie, null, self);
		if (_this.score >= 3 * _this.scorePerZombie) {
			demoSceneStageThree();
		}
	}

	function demoStageThreeUpdater() {
		if (_this.player.y >= _this.rails[3]) {
			demoSceneStageFour();
		}
	}

	function demoStageFourUpdater() {
		_this.game.physics.arcade.overlap(_this.player, _this.obstacles, hitObstacle, null, self);

		if (_this.lives <= 0) {
			demoSceneStageFive();
		}
	}

	function demoStageFiveUpdater() {
		if (_this.player.alive === false) {
			_this.demoOnDown.detach();
			destroyEntity(_this.player);
			mainMenuScene();
		}
	}

	// Future: Should this even be running when the player is dead?
	function mainUpdater() {
		var pointerY;
		var playerY;

		if (_this.lives <= 0) {
			return;
		}
		
		pointerY = _this.game.input.activePointer.y;
		playerY = _this.player.y;
		
		if (_this.game.input.activePointer.isDown && (pointerY < playerY + _this.quarterUnit || pointerY > playerY + _this.quarterUnit * 3)) {
			_this.targetCoord = pointerY - _this.halfUnit;

			if (_this.targetCoord < playerY) {
				_this.playerDirection = _this.UP;
			} else {
				_this.playerDirection = _this.DOWN;
			}
		}

		if (_this.playerDirection === _this.UP && _this.targetCoord < playerY && playerY >= _this.playAreaOffset) {
			_this.player.body.velocity.y = 0 - _this.playerSpeed;
		} else if (_this.playerDirection === _this.DOWN && _this.targetCoord > playerY) {
			_this.player.body.velocity.y = _this.playerSpeed;
		} else {
			_this.player.body.velocity.y = 0;
		}

		if (playerY <= _this.playAreaOffset && _this.player.body.velocity.y < 0) {
			_this.player.body.velocity.y = 0;
		}
		
		_this.game.physics.arcade.overlap(_this.player, _this.zombies, hitZombie, null, self);
		_this.game.physics.arcade.overlap(_this.player, _this.obstacles, crashPlayer, null, self);
	}

	function zombiePhase() {

		//Future: Rollup parts of this function.
		//Future: Consider Group.createMultiple
		//Future: For performance, render zombies that use the same texture in sequence.

		var zombie;
		var i;
		var railPosition;
		var openRails = _this.rails.slice(0);
		var minPopulation;
		var maxPopulation;
		var population;
		var spriteAnimation;

		minPopulation = _this.initMinZombiePop;

		if (_this.difficulty > 8) {
			minPopulation += _this.difficulty - 8;
			minPopulation = minPopulation <= _this.railCount ? minPopulation : _this.railCount;
		}

		maxPopulation = _this.initMaxZombiePop + Math.floor(_this.difficulty / 2);
		maxPopulation = maxPopulation <= _this.railCount ? maxPopulation : _this.railCount;

		population = Math.floor(Math.random() * (maxPopulation - minPopulation + 1)) + minPopulation;

		i = 0;
		while (i < population) {
			spriteAnimation = Math.floor(Math.random() * _this.totalZombieSprites) * 3;
			railPosition = openRails.splice(Math.floor(Math.random() * openRails.length), 1);
			zombie = _this.zombies.create(0, 0, "zombie");
			zombie.scale.setTo(_this.unitScale);

			zombie.y = railPosition[0];
			
			if (_this.driveDirection === _this.RIGHT) {
				zombie.body.velocity.x -= _this.driveSpeed + _this.zombieSpeed;
				zombie.x = _this.spawnPositionRight;
			} else {
				zombie.body.velocity.x += _this.driveSpeed + _this.zombieSpeed;
				zombie.x = _this.spawnPositionLeft - zombie.width;
				spriteAnimation += _this.totalZombieSprites * 3;
			}
			
			zombie.checkWorldBounds = true;
			zombie.events.onOutOfBounds.add(destroyEntity, self);

			zombie.animations.add("walk", [spriteAnimation, spriteAnimation + 1, spriteAnimation + 2], 5, true);
			zombie.animations.play("walk");
			i++;
		}

		_this.gameTimer.add(_this.waveLength, obstaclePhase, self, null);
	}

	function obstaclePhase() {

		//Future: Rollup parts of this function.
		//Future: Consider Group.createMultiple
		var obstacle;
		var railPosition;
		var openRails = _this.rails.slice(0);
		var minPopulation;
		var maxPopulation;
		var population;
		var npcCount;
		var overlayBgCount;
		var obstacleImageIndex;
		var i;

		if (_this.wave === _this.wavesInLevel && _this.lives > 0) {
			
			stopOverlayBg();

			npcCount = countNpcs();
			overlayBgCount = countOverlayBgs();

			if (npcCount > 0 || overlayBgCount > 0) {
				_this.gameTimer.add(_this.waveLength, obstaclePhase, self, null);
			} else {
				finishLevel();
			}

			return;
		}

		minPopulation = _this.initMinObstaclePop + Math.floor(_this.difficulty / 2);
		minPopulation = minPopulation < _this.railCount ? minPopulation : _this.railCount - 1;
		maxPopulation = _this.initMaxObstaclePop + Math.floor((_this.difficulty + 1) / 2);
		maxPopulation = maxPopulation < _this.railCount ? maxPopulation : _this.railCount - 1;

		population = Math.floor(Math.random() * (maxPopulation - minPopulation + 1)) + minPopulation;

		i = 0;
		while (i < population) {
			obstacleImageIndex = Math.floor(Math.random() * (_this.obstacleImages.length));
			railPosition = openRails.splice(Math.floor(Math.random() * (openRails.length)), 1);
			obstacle = _this.obstacles.create(0, 0, _this.obstacleImages[obstacleImageIndex]);
			obstacle.scale.setTo(_this.unitScale);
			
			obstacle.y = railPosition[0];

			if (_this.driveDirection === _this.RIGHT) {
				obstacle.body.velocity.x -= _this.driveSpeed;
				obstacle.x = _this.spawnPositionRight;
			} else {
				obstacle.body.velocity.x += _this.driveSpeed;
				obstacle.x = _this.spawnPositionLeft - obstacle.width;
			}
			
			obstacle.checkWorldBounds = true;
			obstacle.events.onOutOfBounds.add(destroyEntity, self);
			
			i++;
		}

		if (_this.lives > 0) {
			updateScore(_this.scorePerWave);
			updateDifficulty();
			_this.wave++;
		}

		_this.gameTimer.add(_this.waveLength, zombiePhase, self, null);
	}
	
	function overlayBg() {
		var bgOverlayImageIndex;
		var bgOverlay;
		var pxSum = 0;
		var i = 1; // Skip the first entry to ensure that the screen is still filled after it clears
		var activeBgOverlaysLength = _this.activeBgOverlays.length;
		var lastBgOverlay;
		
		while (i < activeBgOverlaysLength) {
			pxSum += _this.activeBgOverlays[i].width;
			
			i++;
		}
		
		if (_this.overlayBgStatus === true) {
			while(pxSum < _this.boundsWidth) {
				bgOverlayImageIndex = Math.floor(Math.random() * (_this.bgOverlayImages.length));
				bgOverlay = _this.buildings.create(0, 0, _this.bgOverlayImages[bgOverlayImageIndex]);
				bgOverlay.scale.setTo(_this.unitScale);
				// Future: Is it nessecary to reset Y after scaling?
				bgOverlay.y = 0;
		
				if (_this.driveDirection === _this.RIGHT) {
					bgOverlay.body.velocity.x = 0 - _this.driveSpeed;
					
					if(activeBgOverlaysLength > 0) {
						lastBgOverlay = _this.activeBgOverlays[activeBgOverlaysLength - 1];
						bgOverlay.x = lastBgOverlay.x + lastBgOverlay.width;
					} else {
						bgOverlay.x = _this.spawnPositionRight;
					}
					
					if(bgOverlay.x > _this.rightBounds){
						bgOverlay.events.onEnterBounds.add(enableOverlayBgOutOfBounds, self);
					} else {
						bgOverlay.events.onOutOfBounds.add(destroyActiveOverlayBg, self);
					}
				} else {
					bgOverlay.body.velocity.x = _this.driveSpeed;
					
					if (activeBgOverlaysLength > 0) {
						lastBgOverlay = _this.activeBgOverlays[activeBgOverlaysLength - 1];
						bgOverlay.x = lastBgOverlay.x - bgOverlay.width;
					} else {
						bgOverlay.x = _this.spawnPositionLeft - bgOverlay.width;
					}
					
					if(bgOverlay.x + bgOverlay.width < _this.leftBounds){
						bgOverlay.events.onEnterBounds.add(enableOverlayBgOutOfBounds, self);
					} else {
						bgOverlay.events.onOutOfBounds.add(destroyActiveOverlayBg, self);
					}
				}
				
				if (activeBgOverlaysLength > 0) {
					pxSum += bgOverlay.width;
				}
				
				bgOverlay.checkWorldBounds = true;
				
				_this.activeBgOverlays.push(bgOverlay);
				
				activeBgOverlaysLength++;
			}
		}
	}
	
	function enableOverlayBgOutOfBounds(bgOverlay) {
		bgOverlay.events.onOutOfBounds.add(destroyActiveOverlayBg, self);
	}
	
	function stopOverlayBg() {
		_this.overlayBgStatus = false;
	}
	
	function startOverlayBg() {
		_this.overlayBgStatus = true;
		overlayBg();
	}
	
	function destroyActiveOverlayBg(bgOverlay) {
		bgOverlay.checkWorldBounds = false;
		_this.activeBgOverlays.shift();
		destroyEntity(bgOverlay);
		overlayBg();
	}
	
	function countOverlayBgs(){
		return _this.activeBgOverlays.length;
	}
	
	function clearOverlayBgs() {
		var i = 0;
		var l = _this.activeBgOverlays.length;
		while(i < l) {
			destroyEntity(_this.activeBgOverlays[i]);
			i++;
		}
		
		_this.activeBgOverlays = [];
	}
	
	function clearNpcs() {
		_this.zombies.removeAll(true);
		_this.obstacles.removeAll(true);
	}

	function countNpcs() {
		var zombieCount = _this.zombies.children.length;
		var obstacleCount = _this.obstacles.children.length;
		var npcCount = zombieCount + obstacleCount;

		return npcCount;
	}

	function createPlayer() {
		//Future: Can this be rewritten as a global callback function that can be applied to all layer?

		if (typeof _this.player !== "undefined") {
			destroyEntity(_this.player);
		}

		_this.player = _this.playerGroup.create(0, 0, "car");
		_this.player.scale.setTo(_this.unitScale);
		_this.player.animations.add("right", [0, 1, 2, 3], 5, true);
		_this.player.animations.add("stopRight", [0], 5, true);
		_this.player.animations.add("left", [4, 5, 6, 7], 5, true);
		_this.player.animations.add("stopLeft", [4], 5, true);

		_this.player.y = (_this.playAreaHeight / 2 - _this.halfUnit + _this.playAreaOffset);
		
		if (_this.driveDirection === _this.RIGHT) {
			_this.player.x = _this.playerOffset;
			_this.player.animations.play("right");
		} else {
			_this.player.x = _this.worldWidth - _this.playerOffset - _this.player.width;
			_this.player.animations.play("left");
		}


		// Future: Checking world bounds is expensive. If bounds only need to be checked for a single direction, do it in the update function - this is cheaper.
		// Future: Consider adopting a standard out of bounds check for all layer.
		_this.player.checkWorldBounds = true;
		_this.player.events.onOutOfBounds.add(destroyEntity, self);
		_this.player.body.collideWorldBounds = true;

		_this.targetCoord = _this.player.y;
	}

	function updateDifficulty() {
		var zombieVelocity;
		var obstacleVelocity;
		var roadBgScrollSpeed;
		var i = 0;
		var l = _this.activeBgOverlays.length;
		
		_this.difficulty = _this.levelDifficulty + Math.floor(_this.wave / _this.wavesPerDifficulty);
		_this.driveSpeed = _this.initDriveSpeed + _this.driveSpeedIncrease * _this.difficulty;
		_this.driveSpeed = _this.driveSpeed <= _this.maxDriveSpeed ? _this.driveSpeed : _this.maxDriveSpeed;
		_this.waveLength = _this.initWaveLength - _this.waveLengthDecrease * _this.difficulty;
		_this.waveLength = _this.waveLength >= _this.minWaveLength ? _this.waveLength : _this.minWaveLength;

		if (_this.driveDirection == _this.RIGHT) {
			zombieVelocity = 0 - _this.driveSpeed - _this.zombieSpeed;
			obstacleVelocity = 0 - _this.driveSpeed;
			roadBgScrollSpeed = 0 - _this.driveSpeed * (_this.game.cache.getImage("background").height / _this.worldHeight); // Future: Part of this can be cached.
		} else {
			zombieVelocity = _this.driveSpeed + _this.zombieSpeed;
			obstacleVelocity = _this.driveSpeed;
			roadBgScrollSpeed = _this.driveSpeed * (_this.game.cache.getImage("background").height / _this.worldHeight); // Future: Part of this can be cached.
		}

		_this.zombies.setAllChildren("body.velocity.x", zombieVelocity);
		_this.obstacles.setAllChildren("body.velocity.x", obstacleVelocity);
		
		while(i < l) {
			// Future: Determine why this is needed and try to eliminate the need for it.
			if(_this.activeBgOverlays[i].alive === true) {
				_this.activeBgOverlays[i].body.velocity.x = obstacleVelocity;
			}
			i++;
		}
		
		_this.roadBg.autoScroll(roadBgScrollSpeed, 0);
	}

	function hitZombie(player, zombie) {
		playEffect("zombie");
		destroyEntity(zombie);
		updateScore(_this.scorePerZombie);
	}

	function hitObstacle(player, obstacle) {
		playEffect("explosion");
		destroyEntity(obstacle);

		if (_this.playerHurt === false) {
			updateLives(-1);
			_this.playerHurt = true;
			player.tint = _this.tintColor;
			_this.game.time.events.add(_this.hurtTime, unTint, self);
		}
	}

	function crashPlayer(player, obstacle) {
		hitObstacle(player, obstacle);

		if (_this.lives === 0) {
			gameOver();
		}
	}

	function gameOver() {
		var loggerOptions = {};
		
		var onDownCb = function () {
			destroyEntity(_this.mainText);
			highScore();
		};
		var gameTimerCb = function () {
			_this.game.input.onDown.addOnce(onDownCb);
		};

		_this.player.body.collideWorldBounds = false;

		_this.player.animations.stop();

		_this.player.body.velocity.y = 0;
		
		if (_this.driveDirection === _this.RIGHT) {
			_this.player.body.velocity.x = 0 - _this.deathSpeed;
		} else {
			_this.player.body.velocity.x = _this.deathSpeed;
		}

		//Future: Don't hard code strings.
		_this.mainText = _this.game.add.text(_this.game.world.centerX, _this.game.world.centerY, "Game Over", {
			font: _this.fontSize,
			fill: "#830303",
			align: "center"
		});
		_this.mainText.x = Math.floor(_this.worldWidth / 2) - Math.floor(_this.mainText.width / 2);
		
		playMusic("game over");
		
		loggerOptions.sanitaryString = "Score: " + _this.score + ", Level: " + _this.level + ", Wave: " + _this.wave;
		
		_this.logger.logEvent("Game", "Game Over", loggerOptions);

		_this.gameTimer.add(_this.sceneInputDelay, gameTimerCb, self, null);

	}

	function highScore() {
		var i = 0;
		var highScoreContents = "\nHigh Scores";
		var highScoreText;
		var l;
		
		
		var gameTimerCb = function () {
			_this.game.input.onDown.addOnce(function(){
				highScoreText.destroy();
				mainMenuScene();
			});
		};
		
		var sortHighScoresCb = function(a,b){
			return b - a;
		};
		
		l = _this.highScores.length;
		
		_this.highScores.push(_this.score);
		_this.highScores.sort(sortHighScoresCb);
		_this.highScores.pop();
		
		_this.updateHighScoresCb();
		
		while (i < l) {
			highScoreContents += "\n\n" + padScore(_this.highScores[i]);
			
			i++;
		}
		
		highScoreContents += "\n\n";
		
		highScoreText = _this.game.add.text(_this.game.world.centerX, _this.game.world.centerY, highScoreContents, {
			font: _this.highScoreFontSize,
			fill: "#830303",
			align: "center"
		});
		
		highScoreText.x = Math.floor(_this.worldWidth / 2) - Math.floor(highScoreText.width / 2);
		highScoreText.y = Math.floor(_this.worldHeight / 2) - Math.floor(highScoreText.height / 2);

		_this.gameTimer.add(_this.sceneInputDelay, gameTimerCb, self, null);
	}

	function unTint() {
		_this.player.tint = 0xFFFFFF;
		_this.playerHurt = false;
	}

	function padScore(score) {
		var newScoreText;

		if (score <= 99999) {
			newScoreText = ("0000" + score).slice(-5);
			return newScoreText;
		}
	}

	function setScore(newScore) {
		var newScoreText;
		
		_this.score = newScore;
		newScoreText = padScore(newScore);
		
		_this.scoreText.setText(newScoreText);
	}

	function updateScore(change) {
		var newScoreText;
		
		change = typeof change !== "undefined" ? change : 0;
		_this.score += change;

		newScoreText = padScore(_this.score);
		
		_this.scoreText.setText(newScoreText);
	}

	function setLives(newLives) {
		_this.lives = newLives;

		refreshLives();
	}

	function updateLives(change) {

		change = typeof change !== "undefined" ? change : 0;
		_this.lives += change;

		refreshLives();
	}

	function refreshLives() {
		var i;

		_this.lifeIcons.removeAll(true);

		i = 0;
		while (i < _this.lives) {
			var lifeIcon;

			// Future: These should be larger
			lifeIcon = _this.lifeIcons.create(0, 0, "lifeIcon");
			lifeIcon.scale.setTo(_this.unitScale);
			lifeIcon.x = _this.halfUnit * (1 + i);
			lifeIcon.y = _this.halfUnit;
			i++;
		}
	}

	function destroyEntity(entity) {
		
		//Future: Determine if this should just throw an error.
		if (typeof entity === "undefined") {
			return;
		}
		
		entity.checkWorldBounds = false;
		_this.destructionArray.push(entity);
	}
	
	// Last minute hack. Fix it later.
	function playMusic(song) {
	
		if (_this.game.cache.checkSoundKey(song) === false) {
			return;
		}
		
		if (typeof song === 'undefined') {
			song = _this.music.song;
		} else {
			_this.music.song = song;
		}
		
		if(_this.music.songs.hasOwnProperty(song) === false) {
			 _this.music.songs[song] = _this.game.add.audio(song);
		}
		
		if (_this.game.cache.isSoundDecoded(song) === false) {
			return;
		}
	
		if (_this.music.playing !== null) {
			
			if (_this.music.playing.key === song) {
				return;
			}
			
			_this.music.playing.stop();
		}
		
		_this.music.playing = _this.music.songs[song];
		
		_this.music.playing.play("", 0, 0.7, true, true);
	}
	
	// Last minute hack. Fix it later.
	function playEffect(effect) {
	
		if (_this.game.cache.checkSoundKey(effect) === false) {
			return;
		}
		
		if (typeof effect === 'undefined') {
			effect = _this.effects.effect;
		} else {
			_this.music.effect = effect;
		}
		
		if(_this.effects.sounds.hasOwnProperty(effect) === false) {
			 _this.effects.sounds[effect] = _this.game.add.audio(effect);
		}
		
		if (_this.game.cache.isSoundDecoded(effect) === false) {
			return;
		}
	
		if (_this.effects.playing !== null) {
			_this.effects.playing.stop();
		}
		
		_this.effects.playing = _this.effects.sounds[effect];
		
		_this.effects.playing.play("", 0, 1, false, true);
	}

	_this.game = undefined;
	_this.entities = undefined;
	_this.actors = undefined;
	_this.npcs = undefined;
	_this.playerGroup = undefined;
	_this.uiElements = undefined;
	_this.theKcRoadLawyer = undefined;
	_this.client = undefined;
	_this.player = undefined;
	_this.zombies = undefined;
	_this.obstacles = undefined;
	_this.buildings = undefined;
	_this.courtHouse = undefined;
	_this.apartment = undefined;
	_this.scoreText = undefined;
	_this.mainText = undefined;
	_this.gameTimer = undefined;
	_this.roadBg = undefined;
	_this.waveLength = undefined;
	_this.difficulty = undefined;
	_this.driveSpeed = undefined;
	_this.baseWidth = undefined;
	_this.baseHeight = undefined;
	_this.worldWidth = undefined;
	_this.worldHeight = undefined;
	_this.boundsWidth = undefined;
	_this.boundsHeight = undefined;
	_this.leftBounds = undefined;
	_this.rightBounds = undefined;
	_this.topBounds = undefined;
	_this.bottomBounds = undefined;
	_this.playAreaHeight = undefined;
	_this.fullUnit = undefined;
	_this.halfUnit = undefined;
	_this.quarterUnit = undefined;
	_this.targetCoord = undefined;
	_this.horizontalSpawnPositionOffset = undefined;
	_this.verticalSpawnPositionOffset = undefined;
	_this.playerOffset = undefined;
	_this.demoNpcSpacing = undefined;
	_this.scoreRightOffset = undefined;
	_this.scoreTopOffset = undefined;
	_this.playAreaOffset = undefined;
	_this.score = undefined;
	_this.wave = undefined;
	_this.deathSpeed = undefined;
	_this.playerSpeed = undefined;
	_this.zombieSpeed = undefined;
	_this.initDriveSpeed = undefined;
	_this.spawnPositionRight = undefined;
	_this.spawnPositionLeft = undefined;
	_this.spawnPositionTop = undefined;
	_this.waveLengthDecrease = undefined;
	_this.driveSpeedIncrease = undefined;
	_this.level = undefined;
	_this.updateFunction = undefined;
	_this.sceneNpcSpeed = undefined;
	_this.levelDifficulty = undefined;
	_this.wavesInLevel = undefined;
	_this.demoOnDown = undefined;
	_this.unitScale = undefined;
	_this.lives = undefined;
	_this.lifeIcons = undefined;
	_this.driveDirection = undefined;
	_this.showMenuCb = undefined;
	_this.createCb = undefined;
	_this.updateHighScoresCb = undefined;
	_this.textScaling = undefined;
	_this.fontSize = undefined;
	_this.highScoreTextScaling = undefined;
	_this.highScoreFontSize = undefined;
	
	_this.UP = 360;
	_this.DOWN = 180;
	_this.RIGHT = 90;
	_this.LEFT = 270;
	_this.destructionArray = [];
	_this.rails = [];
	_this.activeBgOverlays = [];
	_this.overlayBgStatus = false;
	_this.playerDirection = _this.UP;
	_this.playerHurt = false;
	_this.tintColor = "0xFF0000";
	_this.totalZombieSprites = 4;
	_this.hurtTime = 500;
	_this.sceneInputDelay = 1000;
	_this.demoSceneDelay = 3000;
	_this.sceneNpcSpeedSeconds = 2;
	_this.initLives = 3;
	_this.initWaveLength = 1000;
	_this.minWaveLength = 500;
	_this.initWavesInLevel = 10;
	_this.wavesIncreasePerLevel = 10;
	_this.railCount = 4;
	_this.wavesPerDifficulty = 5;
	_this.difficultyIncreasePerLevel = 2;
	_this.initDriveSpeedSeconds = 6;
	_this.maxDriveSpeedSeconds = 2;
	_this.playerSpeedSeconds = 1;
	_this.zombieSpeedPercentage = 0.25;
	_this.maxDriveSpeedDifficulty = 10; //Future: This should be specified in seconds or MS.
	_this.minWaveLengthDifficulty = 10; //Future: This should be specified in seconds or MS.
	_this.deathSpeedPercentage = 0.5;
	_this.initMinZombiePop = 1;
	_this.initMaxZombiePop = 1;
	_this.initMinObstaclePop = 0;
	_this.initMaxObstaclePop = 1;
	_this.scorePerWave = 1;
	_this.scorePerZombie = 10;
	_this.scorePerClientDrop = 50;
	_this.bgOverlayImages = [
		"fence",
		"trees",
		"grocerystore",
		"factory",
		"barricades",
		"trash",
		"firehydrant",
		"bench"
	];
	_this.obstacleImages = [
		"barrels",
		"barricade",
		"cones",
		"crates"
	];
	_this.highScores = [
		0,
		0,
		0,
		0,
		0
	];
	
	// Last minute hack. Fix it later.
	_this.music = {
		songs: {},
		playing: null,
		song: undefined
	};
	
	// Last minute hack. Fix it later.
	_this.effects = {
		sounds: {},
		playing: null,
		effect: undefined
	};
	
	//Private Properties
	_this.logger = logger;

	this.startGame = function () {
		startGame();
	};

	this.startDemo = function () {
		demoSceneStageOne();
	};

	this.runGame = function (newHighScores, newShowMenuCb, newUpdateHighScoresCb, newCreateCb) {
		var phaserConfig = {
				enableDebug: false,
				renderer: Phaser.AUTO,
				parent: "phaserparent",
				antialias: false,
				state: {
					preload: preload,
					create: create,
					update: update
				}
				
		};
		
		if (typeof newHighScores !== "undefined") {
			_this.highScores = newHighScores;
		}
		
		_this.showMenuCb = typeof newShowMenuCb !== "undefined" ? newShowMenuCb : function(){};
		_this.updateHighScoresCb = typeof newUpdateHighScoresCb !== "undefined" ? newUpdateHighScoresCb : function(){};
		_this.createCb = typeof newCreateCb !== "undefined" ? newCreateCb : function(){};

		_this.baseWidth = document.documentElement.clientWidth;
		_this.baseHeight = Math.floor(_this.baseWidth * 0.5625);

		if (_this.baseHeight > document.documentElement.clientHeight) {
			_this.baseHeight = document.documentElement.clientHeight;
			_this.baseWidth = Math.floor(_this.baseHeight / 0.5625);
		}
		
		phaserConfig.width = _this.baseWidth;
		phaserConfig.height = _this.baseHeight;

		_this.game = new Phaser.Game(phaserConfig);
	};
	
	this.getHighScores = function () {
		return _this.highScores;
	};
	
	//Uncomment for debugging
	//this._this = _this;

};
