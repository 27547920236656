"use strict";

module.exports = {
	enableAnalytics: false,
	
	//Debugging:
	betaTest: false,
	revealPrivateScope: false,
	enableLocalLog: false,
};
